import React, { useState, useEffect, useCallback } from "react";
import { StaticQuery, graphql, Link } from "gatsby"; 
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import image from './img/coupon-ornamental-s.png'
import garageOpenerImage from './img/gate-coupon.png'
import { dateOutputInXDays } from "../../helpers/all";

const getCurrentYear = () => new Date().getFullYear();

const TanklessWaterHeaterPromotion = () => (
  <>
        <div
          className={`rounded-lg mb-2 shadow bg-nuetral-900  `}
        >
          <div className="px-8 pt-6 relative z-1">
            <h4 className="text-brand-600 font-bold text-3xl md:text-4xl">
            $150 OFF
            </h4>
            <h3 className="text-gray-700 font-bold text-lg md:text-xl">
            Any New Iron Gate + Opener Combo
            </h3>
            <p className="text-sm text-center text-gray-500 italic">
              *Offer expires {dateOutputInXDays(9)}, {getCurrentYear()}
            </p>
            <Link
              to={'/contact'}
              className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700 focus:outline-none px-5 py-3 rounded-full bg-white shadow font-display"
            >
              Claim Offer
            </Link>
          </div>
          <img 
            src={image}
            className="-mt-72 z-0"
            alt="Iron Gate Services Sacramento"
          />  
        </div>
        
        <div
          className={`rounded-lg mb-2 shadow bg-white`}
          style={{
            background: "linear-gradient(145deg, rgb(183, 134, 40) 50%, rgb(211, 211, 211) 50%)",
          }}
        >
          <div className="px-8 pt-6 relative z-1">
            <h4 className="text-white font-bold text-3xl md:text-4xl">
              $50 OFF
            </h4>
            <h3 className="text-white font-bold text-lg md:text-xl">
              On any purchase of $950 or more 
            </h3>
            <h3 className="text-white font-normal text-sm md:text-base">
              Call Today for a Fence or Gate Installation estimate
            </h3>
            <Link
              to={'/contact'}
              className="button-active-bhvr my-2 inline-block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out text-brand-600 focus:text-brand-700 focus:outline-none px-5 py-3 rounded-full bg-white shadow font-display"
            >
              Claim Offer
            </Link>
          </div>
          <div className="px-8 py-3">
            <img 
              src={garageOpenerImage}
              className="-my-20 z-0"
              alt="Iron Gate Services Sacramento"
            />  
            <p className="text-sm text-center text-gray-500 italic">
              *Offer expires {dateOutputInXDays(9)}, {getCurrentYear()}
            </p>
          </div>
        </div>
  </>
);

export default TanklessWaterHeaterPromotion;
